<template>
  <div class="error">
    <div class="error-main">
      <p class="icon"></p>
      <h1 class="title">{{ $t('error.title') }}</h1>
      <p class="desc">{{ $t('error.desc') }}</p>
      <p class="desc" v-if="from === 'paypal'">{{ $t('paypal.desc') }}</p>
    </div>
    <div class="footer">
      <router-link to="/">
        <mi-button>
          {{ $t('paymentResult.goMibi') }}
        </mi-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { MiButton } from '@/components'

export default {
  components: {
    MiButton
  },
  data() {
    const from = this.$route.params.from
    return { from: from }
  }
}
</script>

<style lang="scss" scoped>
.error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 80px 90px;
  .error-main {
    text-align: center;
    flex: 1;
    .icon {
      width: 90px;
      height: 90px;
      margin: 0 auto 27px;
      @include level-bg-image('failed');
    }
    .title {
      font-weight: 500;
      font-size: 72px;
      color: #000;
    }
    .desc {
      font-size: 38px;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 27px;
    }
  }
}
</style>
